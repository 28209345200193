import mediaConfig from '@/configs/http-config';
// 深拷贝/浅拷贝对象
const Clone = function (stuff: any, isDeep = false): any {
  let newStuff;
  if (stuff && typeof (stuff as any).getMonth === 'function') {
    newStuff = new Date(stuff as any);
  } else if (Array.isArray(stuff) || (stuff && typeof (stuff as any).length === 'number' && typeof (stuff as any).map === 'function')) {
    newStuff = [];
    for (let i = 0; i < (stuff as any).length; i++) {
      newStuff[i] = isDeep ? Clone((stuff as any)[i] as any) : (stuff as any)[i];
    }
  } else if (typeof (stuff) === 'object') {
    newStuff = {};
    for (const key in stuff as any) {
      if (Object.prototype.hasOwnProperty.call(stuff as any, key)) {
        (newStuff as { [key: string]: any })[key] = isDeep ? Clone((stuff as { [key: string]: any })[key]) : (stuff as { [key: string]: any })[key];
      }
    }
  } else {
    newStuff = stuff;
  }
  try {
    // eslint-disable-next-line no-proto
    (newStuff as any).__proto__ = (stuff as any).__proto__;
    // eslint-disable-next-line no-empty
  } catch (e) { }
  return newStuff as any;
};

// const Clone = function () {
//   console.log('--->>>')
// }

const formatPhoneNumber = (number: string) => {
  if (number) {
    if (number.length > 3 && number[3] !== '-') {
      number = number.substring(0, 3) + '-' + number.substring(3);
    }
    if (number.length > 7 && number[7] !== '-') {
      number = number.substring(0, 7) + '-' + number.substring(7);
    }
  }
  return number;
};

function clearSession() {
  const keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (keys) {
    for (let i = keys.length - 1; i >= 0; i--) {
      if (keys[i].includes('SESSION')) {
        document.cookie = keys[i] + '=0; Domain=.muncho.io; expires=' + new Date(0).toUTCString();
      }
    }
  }
}

export default {
  Clone,
  formatPhoneNumber,
  clearSession
};
