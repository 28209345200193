
import { defineComponent, onMounted, ref, watch, computed } from "vue";
// import { ImagePreview } from "vant";
import {
  showImagePreview,
  showConfirmDialog,
  showLoadingToast,
  closeToast,
  showToast,
} from "vant";
import { useStore } from "vuex";
import getActiveList from "@/service/getters/getActiveList";
import getHistoryList from "@/service/getters/getHistoryList";
import userLogout from "@/service/postors/userLogout";
import deleteDeliveries from "@/service/delete/deleteDeliveries";
import tools from "@/uitls/tools";

export default defineComponent({
  name: "HomePage",
  setup() {
    let loading = ref(false);
    let showTracking = ref(false);
    let refreshing = ref(false);
    let finished = ref(false);
    let tabAct = ref(0);
    let page = 0;
    let totalNum = 0;
    let trackingURL = ref("");
    let isLoading = false;
    const tabList = [
      { name: "In progress", value: 0 },
      { name: "History", value: 1 },
    ];
    const store = useStore();
    let list = ref<any[]>([]);
    // let shouldRefresh = computed(() => {
    //   return store.state.shouldRefresh;
    // });
    const tabChange = () => {
      finished.value = false;
      if (tabAct.value === 0) {
        page = 0;
        getActiveOrder();
      } else {
        page = 0;
        getHisyoryOrder();
      }
    };
    const cancelOrder = async (id: string) => {
      try {
        showLoadingToast({
          duration: 0,
          forbidClick: true,
          message: "Loading...",
        });
        await deleteDeliveries(id);
        onRefresh();
        closeToast();
      } catch (e: any) {
        closeToast();
        showToast({
          message: "The delivery cannot be cancelled.",
          position: "bottom",
        });
      }
    };
    const closeTrackingDialog = () => {
      console.log("closeTrackingDialog");
      trackingURL.value = "";
    };
    // watch(
    //   shouldRefresh,
    //   (newValue: any, oldValue: any) => {
    //     console.log(`shouldRefresh changed from ${oldValue} to ${newValue}`);
    //     if (newValue === true) {
    //       store.commit("needRefresh", false);
    //       tabAct.value = 0;
    //       getActiveOrder();
    //     }
    //   },
    //   { immediate: true }
    // );
    const getActiveOrder = async () => {
      try {
        const params = {
          pageCapacity: 5,
          pageNumber: page,
        };
        const res = await getActiveList(params);
        const data = res.data;
        data.map((item: any) => {
          // item.imgAry = [
          //   "https://img01.yzcdn.cn/vant/apple-1.jpg",
          //   "https://img01.yzcdn.cn/vant/apple-2.jpg",
          //   "https://img01.yzcdn.cn/vant/apple-3.jpg",
          //   "https://img01.yzcdn.cn/vant/apple-4.jpg",
          // ];
          item.imgAry = [item.dropOffVerificationImageUrl];
        });
        totalNum = res.totalCount;
        loading.value = false;
        if (page === 0) {
          list.value = data;
        } else {
          const listData = list.value;
          listData.push(...data);
          list.value = listData;
        }
        if (list.value.length >= totalNum) {
          finished.value = true;
        }
        page += 1;
        refreshing.value = false;
        isLoading = false;
      } catch (e: any) {
        refreshing.value = false;
        isLoading = false;
        console.log(e);
      }
    };
    const getHisyoryOrder = async () => {
      try {
        const params = {
          pageCapacity: 5,
          pageNumber: page,
        };
        const res = await getHistoryList(params);
        const data = res.data;
        totalNum = res.totalCount;
        loading.value = false;
        if (page === 0) {
          list.value = data;
        } else {
          const listData = list.value;
          listData.push(...data);
          list.value = listData;
        }
        if (list.value.length >= totalNum) {
          finished.value = true;
        }
        page += 1;
        refreshing.value = false;
        isLoading = false;
      } catch (e: any) {
        refreshing.value = false;
        isLoading = false;
        console.log(e);
      }
    };
    const getHourFromTimestamp = (timestamp: number) => {
      const date = new Date(timestamp); // 将时间戳转换为毫秒
      const hour = date.getHours(); // 获取小时数
      const minutes = date.getMinutes(); // 获取小时数
      let minutesStr = minutes + "";
      if (minutes < 10) {
        minutesStr = "0" + minutes;
      }
      if (hour < 12) {
        const todayTime = hour + ":" + minutesStr + " AM";
        return `Delivery starts at ${todayTime}`;
      } else {
        let todayTime = "";
        if (hour === 12) {
          todayTime = hour + ":" + minutesStr + " PM";
        } else {
          todayTime = hour - 12 + ":" + minutesStr + " PM";
        }
        return `Delivery starts at ${todayTime}`;
      }
    };
    const getUpdateTime = (timestamp: number) => {
      const date = new Date(timestamp); // 将时间戳转换为毫秒
      const hour = date.getHours(); // 获取小时数
      const minutes = date.getMinutes(); // 获取小时数
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const dateStr = "Update time: " + year + "-" + month + "-" + day;
      let minutesStr = minutes + "";
      if (minutes < 10) {
        minutesStr = "0" + minutes;
      }
      if (hour < 12) {
        const todayTime = hour + ":" + minutesStr + " AM";
        return `${dateStr} ${todayTime}`;
      } else {
        let todayTime = "";
        if (hour === 12) {
          todayTime = hour + ":" + minutesStr + " PM";
        } else {
          todayTime = hour - 12 + ":" + minutesStr + " PM";
        }
        return `${dateStr} ${todayTime}`;
      }
    };

    const openTrackingDialog = (url: string) => {
      trackingURL.value = url;
      showTracking.value = true;
    };
    const getPhoneStr = (input: string) => {
      if (!input) {
        return "";
      }
      input = input.toString(); // 将数字转换为字符串

      // 分割前六位和后面的位数
      let firstPart = input.slice(0, 6);
      let secondPart = input.slice(6);

      // 在前六位中每三位添加一个空格
      let formattedFirstPart = firstPart.slice(0, 3) + " " + firstPart.slice(3);

      return formattedFirstPart + " " + secondPart; // 返回格式化后的数字
    };

    onMounted(() => {
      // getActiveOrder();
    });
    const onRefresh = () => {
      finished.value = false;
      loading.value = true;
      page = 0;
      onLoad();
    };
    const onLoad = () => {
      console.log("onLoad 啦");
      if (isLoading) {
        return;
      }
      isLoading = true;
      if (!finished.value) {
        // loading.value = true;
        if (tabAct.value === 0) {
          getActiveOrder();
        } else {
          getHisyoryOrder();
        }
      }
    };
    const showCancelDialog = (id: string) => {
      showConfirmDialog({
        confirmButtonText: "Confirm",
        cancelButtonText: "Close",
        confirmButtonColor: "#FF7D41",
        message: "Cancel order?",
      })
        .then(() => {
          // on confirm
          cancelOrder(id);
        })
        .catch(() => {
          // on cancel
        });
    };

    return {
      store,
      getActiveOrder,
      getHisyoryOrder,
      getHourFromTimestamp,
      getUpdateTime,
      onRefresh,
      onLoad,
      list,
      tabChange,
      tabList,
      tabAct,
      loading,
      refreshing,
      finished,
      isLoading,
      getPhoneStr,
      showTracking,
      trackingURL,
      openTrackingDialog,
      closeTrackingDialog,
      cancelOrder,
      showCancelDialog,
    };
  },
  data() {
    return {
      imagePreviewShow: false,
      // loading: false,
      // refreshing: false,
      // finished: true,
      // list: [] as any[],
      currentPriveAry: [] as any[],
    };
  },
  methods: {
    openPriveView(imgAry: any) {
      // console.log("openPriveView---->>.");
      // this.imagePreviewShow = true;
      // console.log(imgAry);
      // // this.currentPriveAry = imgAry;
      // const images = imgAry.map((imgSrc: string) => ({
      //   url: imgSrc,
      // }));
      // new ImagePreview({
      //   images,
      //   startPosition: 0,
      // });
      showImagePreview(imgAry);
    },
    hideImagePreview() {
      console.log("hideImagePreview");
    },
    showLogout() {
      showConfirmDialog({
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#FF7D41",
        message: "Log out?",
      })
        .then(() => {
          // on confirm
          this.logout();
        })
        .catch(() => {
          // on cancel
        });
    },
    async logout() {
      try {
        showLoadingToast({
          duration: 0,
          forbidClick: true,
          message: "Loading...",
        });
        await userLogout();
        tools.clearSession();
        this.store.commit("clearLogin");
        closeToast();
        this.$router.replace({
          path: "/login",
        });
      } catch (e: any) {
        closeToast();
        console.log(e);
      }
    },
    jumpToAddDelivery() {
      this.store.commit("clearInfo");
      this.$router.push({
        path: "/addDelivery",
        query: {
          date: new Date().getTime(),
        },
      });
      // this.$router.push({
      //   path: "/infoConfirm",
      //   query: {
      //     date: new Date().getTime(),
      //   },
      // });
    },
    getImagePath(imgSrc: string) {
      return require(`@/assets/image/${imgSrc}`);
    },
  },
});
