
import { defineComponent } from "vue";

export default defineComponent({
  name: "TestTwoPage",
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
