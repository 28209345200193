
import { defineComponent } from "vue";

export default defineComponent({
  name: "TestOnePage",
  data() {
    return {
      count: 0,
      externalId: '',
    };
  },
  methods: {
    jump() {
      this.$router.push({
        path: "/testTwo",
      });
    },
  },
});
