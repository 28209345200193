import axios from '@/uitls/axios/request';
import processLocationQuery from '@/service/dataProcessors/LocationQueryProcessors';
const uri = '/services/location/query';
interface RequestBody {
  longitude?: number;
  latitude?: number;
  address?: string;
}
export default async function getLocationQuery (requestBody: RequestBody): Promise<any> {
  const response = await axios.get(uri, { params: requestBody });
  const data = response.data;
  return processLocationQuery(data);
}
