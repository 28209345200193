import axios from '@/uitls/axios/request';
const uri = '/services/location/autocomplete';
interface RequestBody {
  address: string;
}
export default async function getLocationAutoComplete (requestBody: RequestBody): Promise<any> {
  const response = await axios.get(uri, { params: requestBody });
  const data = response.data;
  return data;
}
