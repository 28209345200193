import { createApp } from 'vue'
import App from './App.vue'
import Vant, { Lazyload } from 'vant';
import 'lib-flexible/flexible';
import 'vant/lib/index.css';
import store from './store';

import router from './router'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Vant);
app.use(Lazyload);

// const baseSize = 37.5; // 这个是设计稿中1rem的大小。

// function setRem() {
//   // 实际设备页面宽度和设计稿的比值
//   const width = document.documentElement.clientWidth;
//   const scale = width / 375;
//   // 计算实际的rem值并赋予给html的font-size
//   document.documentElement.style.fontSize = (baseSize * scale) + 'px';
// }
// setRem();
// window.addEventListener('resize', () => {
//   setRem();
// });
app.mount('#app')
