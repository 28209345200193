import axios from '@/uitls/axios/request';
import RequestError from '@/core/RequestError';
interface RequestBody {
  fullPhoneNumber: string;
  verificationCode: string;
  keepCredentials: boolean;
}
const uri = '/login';
export default async function userLogin (requestBody: RequestBody): Promise<null> {
  try {
    const response = await axios.post(uri, requestBody);
    const data = response.data;
    if (response.status === 200) {
      return null;
    } else {
      throw new RequestError(response.status, response.statusText, data);
    }
  } catch (e: any) {
    console.log(e);
    throw new RequestError(e.response.status, e.response.statusText, e.response.data);
  }
}
