import axios from '@/uitls/axios/request';
import RequestError from '@/core/RequestError';
import UserData from '@/interfaces/UserData';
const uri = '/user';
export default async function userInfo (): Promise<UserData> {
  try {
    const response = await axios.get(uri);
    const data = response.data;
    if (response.status < 300) {
      return data;
    } else {
      throw new RequestError(response.status, response.statusText, data);
    }
  } catch (e: any) {
    console.log(e);
    throw new RequestError(e.response.status, e.response.statusText, e.response.data);
  }
}
