import { createRouter, createWebHistory } from 'vue-router'
// import { defineAsyncComponent } from 'vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import AddDelivery from '@/views/AddDelivery.vue'
import Location from '@/views/Location.vue'
import TestOne from '@/views/TestOne.vue'
import TestTwo from '@/views/TestTwo.vue'
import InfoConfirm from '@/views/InfoConfirm.vue'

const router = createRouter({
  // history: createWebHashHistory(),  // hash 模式
  history: createWebHistory(),  // history 模式
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: Home,
      meta: {
        title: '首页'
      },
    },
    {
      path: '/addDelivery',
      name: 'AddDeliveryPage',
      component: AddDelivery,
      meta: {
        title: '增加配送',
        keepAlive: true
      },
    },
    {
      path: '/location',
      name: 'LocationPage',
      component: Location,
      meta: {
        title: '选择地址'
      },
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: Login,
      meta: {
        title: '登录',
      },
    },
    {
      path: '/testOne',
      name: 'TestOnePage',
      component: TestOne,
      meta: {
        title: '测试1',
        keepAlive: true
      },
    },
    {
      path: '/testTwo',
      name: 'TestTwoPage',
      component: TestTwo,
      meta: {
        title: '测试2'
      },
    },
    {
      path: '/infoConfirm',
      name: 'InfoConfirm',
      component: InfoConfirm,
      meta: {
        title: '信息确认'
      },
    },
  ]
})

// // 全局路由守卫
// router.beforeEach((to, from, next)=>{
//   // console.log(to, from)
//   if (to.meta.title) {
//     document.title = `${to.meta.title}`;
//   }
//   next()
// })

// router.afterEach((to, from)=>{
//   // console.log(to, from)
//   console.log('afterEach')
// })

export default router