import UserData from '@/interfaces/UserData';
import userInfo from '@/service/getters/userInfo';

import tools from '@/uitls/tools';
export default class User {
  public static _default: UserData = {
    fullPhoneNumber: '',
    phoneNumber: '',
    internationalCode: '',
    userType: 4
  };

  public static get default() {
    return  tools.Clone(User._default, true);
  }

  public static async getUser () {
    return await userInfo()
  }
}
