import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import User from '@/models/User';

const store = createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state() {
    return {
      editNum: 1, //提交信息页面编辑次数, 用户有再编辑了就+1，从2开始算再次编辑的
      shouldRefresh: false,
      user: {
        fullPhoneNumber: '',
        phoneNumber: '',
        internationalCode: '',
        userType: 4
      },
      addDeliveryInfo: {
        externalId: "",
        orderSubtotal: "",
        pickNumber: "",
        merchantName: "",
        merchantPhone: "",
        deliveryTime: "",
        orderNotes: "",
        dropOffInstruction: "",
        customerPhone: "",
        timeChecked: "asap",
        merchatDetailAddress: "",
        customerDetailAddress: "",
        selectDeliveryTime: "",
        value1: 0,
        deliveryHour: "",
        deliveryMinute: "",
        firstName: "",
        lastName: "",
        contactlessDropOff: false
      },
      businessAddress: {
      },
      customerAddress: {}
    };
  },
  mutations: {
    updateUser(state: any, newUser) {
      state.user = newUser
    },
    updateBusinessAddress(state: any, locationInfo) {
      state.businessAddress = locationInfo
    },
    updateUserAddress(state: any, locationInfo) {
      state.customerAddress = locationInfo
    },
    clearInfo(state: any) {
      state.editNum = 1
      state.addDeliveryInfo = {
        externalId: "",
        orderSubtotal: "",
        tip: "",
        pickNumber: "",
        merchantName: "",
        merchantPhone: "",
        deliveryTime: "",
        orderNotes: "",
        dropOffInstruction: "",
        customerPhone: "",
        timeChecked: "asap",
        merchatDetailAddress: "",
        customerDetailAddress: "",
        selectDeliveryTime: "",
        value1: 0,
        deliveryHour: "",
        deliveryMinute: "",
        firstName: "",
        lastName: "",
        contactlessDropOff: false
      }
      state.businessAddress = {}
      state.customerAddress = {}
    },
    clearLogin(state: any) {
      state.user = {
        fullPhoneNumber: '',
        phoneNumber: '',
        internationalCode: '',
        userType: 4
      }
    },
    needRefresh(state: any, shouldRefresh) {
      state.shouldRefresh = shouldRefresh
    },
    reEdit(state: any){
      state.editNum += 1
    }
  },
  actions: {
    // 其他 actions...
  },
});

export default store
