
import { defineComponent, onMounted } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";
import getLocationAutoComplete from "@/service/getters/getLocationAutoComplete";
import getLocationQuery from "@/service/getters/getLocationQuery";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LocationPage",
  components: {
    GoogleMap,
    Marker,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    return {
      store,
      router,
    };
  },

  data() {
    return {
      center: { lat: 38.552539, lng: -121.734703 },
      searchVal: "",
      autocompleteList: [],
      locationInfo: {
        address: "Davis, CA,USA",
        latitude: 38.552539,
        longitude: -121.734703,
        manuallySet: undefined,
      },
      debounceTimer: 0, // Add this line
    };
  },
  methods: {
    onSearch() {
      console.log(this.searchVal);
    },
    goBack() {
      this.$router.back();
    },
    async locationChange(value: string) {
      if (this.debounceTimer != null) {
        clearTimeout(this.debounceTimer);
      }
      this.debounceTimer = setTimeout(async () => {
        const address = value;
        this.autocompleteList = await getLocationAutoComplete({ address });
      }, 300);
      // const address = value;
      // this.autocompleteList = await getLocationAutoComplete({ address });
    },
    chooseAddress(value: string) {
      this.searchVal = value;
      this.autocompleteList = [];
      this.submitSearch();
    },
    async submitSearch() {
      const address = this.searchVal;
      console.log("submitSearch----->>>>");
      console.log(address);
      this.locationInfo = await getLocationQuery({ address });
      console.log(this.locationInfo);
    },
    changeLocation() {
      (this.locationInfo as any).manuallySet = true;
      const locationType = this.$route.query.addressType;
      if (locationType === "0") {
        this.store.commit("updateBusinessAddress", this.locationInfo);
      } else {
        this.store.commit("updateUserAddress", this.locationInfo);
      }

      this.$router.back();
      // this.router.back();
    },
  },
});
