
import { defineComponent, onMounted, ref, nextTick, computed, onDeactivated } from "vue";
import { useStore } from "vuex";
import submitIno from "@/service/postors/submitIno";
import { showLoadingToast, closeToast, showToast } from "vant";

export default defineComponent({
  name: "AddDeliveryPage",
  // components: {
  //   Location,
  // },
  setup() {
    // 组件挂载后执行的操作
    const store = useStore();
    let addDeliveryInfo = computed(() => store.state.addDeliveryInfo);
    // let merchantAddress = ref("");
    // let customerAddress = ref<string | undefined>("");
    const merchantAddress = computed(() => store.state.businessAddress.address);
    const customerAddress = computed(() => store.state.customerAddress.address);
    const contactlessPopover = ref(false);
    const merchantPhoneRef = ref<any>(null);
    const customerPhoneRef = ref<any>(null);
    const aptValue = ref("Apt"); //ref<string>('Apt')
    const customerAptValue = ref("Apt");
    const contactlessDropOffAry = [
      { text: "true", value: true },
      { text: "false", value: false },
    ];
    const aptValueOption = [
      { text: "Apt", value: "Apt" },
      { text: "Suite", value: "Suite" },
    ];
    onMounted(() => {
      console.log("onMounted---->");
      console.log(store.state.businessAddress);
      // merchantAddress.value = store.state.businessAddress.address;
      // customerAddress.value = store.state.customerAddress.address;
    });

    const setContactless = (action: any) => {
      addDeliveryInfo.value.contactlessDropOff = action.value;
    };

    onDeactivated(() => {
      console.log("页面已经被关闭");
    });
    return {
      merchantAddress,
      customerAddress,
      merchantPhoneRef,
      customerPhoneRef,
      store,
      aptValue,
      aptValueOption,
      addDeliveryInfo,
      customerAptValue,
      contactlessDropOffAry,
      contactlessPopover,
      setContactless,
    };
  },
  data() {
    return {
      // externalId: "",
      // orderSubtotal: "",
      // pickNumber: "",
      // merchantName: "",
      // merchantPhone: "",
      // deliveryTime: "",
      // orderNotes: "",
      // customerPhone: "",
      showBottom: false,
      // timeChecked: "asap",
      // merchatDetailAddress: "",
      // customerDetailAddress: "",
      // selectDeliveryTime: "",
      // value1: 0,
      option1: [
        { text: "AM", value: 0 },
        { text: "PM", value: 1 },
      ],
      // deliveryHour: "",
      // deliveryMinute: "",
    };
  },
  methods: {
    async submit() {
      const deliveryTime =
        this.addDeliveryInfo.timeChecked === "asap" ? Date.now() : this.getTimeStamp();
      if (!this.addDeliveryInfo.externalId) {
        this.showBottomToast("External id is empty");
        return;
      }
      if (!this.addDeliveryInfo.orderSubtotal) {
        this.showBottomToast("Order subtotal is empty");
        return;
      }
      if (!this.addDeliveryInfo.pickNumber) {
        this.showBottomToast("Pickup number is empty");
        return;
      }
      if (!this.addDeliveryInfo.merchantName) {
        this.showBottomToast("Merchant name is empty");
        return;
      }
      if (!this.addDeliveryInfo.merchantPhone) {
        this.showBottomToast("Merchant phone is empty");
        return;
      }
      // if (!this.merchantAddress || !this.addDeliveryInfo.merchatDetailAddress) {
      //   this.showBottomToast("Merchant address is empty");
      //   return;
      // }
      if (!this.merchantAddress) {
        this.showBottomToast("Merchant address is empty");
        return;
      }
      if (!this.addDeliveryInfo.deliveryTime) {
        this.showBottomToast("Delivery time is empty");
        return;
      }
      if (!this.addDeliveryInfo.customerPhone) {
        this.showBottomToast("Customer phone is empty");
        return;
      }
      // if (
      //   !this.customerAddress ||
      //   !this.addDeliveryInfo.customerDetailAddress
      // ) {
      //   this.showBottomToast("Customer address is empty");
      //   return;
      // }
      if (!this.customerAddress) {
        this.showBottomToast("Customer address is empty");
        return;
      }
      if (this.addDeliveryInfo.timeChecked != "asap") {
        if (deliveryTime < Date.now()) {
          //时间不对不能提交
          this.showBottomToast("The selected delivery time is incorrect");
          return;
        }
      }
      try {
        // const params = {
        //   externalId: "dhashdauygdyuasgdsuya", //this.externalId,
        //   orderSubtotal: 1850, //this.orderSubtotal,
        //   pickupTime: Date.now(), //deliveryTime,
        //   pickupAddress: "1280 Olive Dr, Apt 217, Davis, CA, USA", //this.merchantAddress + " " + this.merchatDetailAddress,
        //   pickupPhoneNumber: "9164778934", // this.merchantPhone.split("-").join(""),
        //   pickupBusinessName: "Itea Test", //this.merchantName,
        //   dropOffAddress: "426 A Street, Davis, CA, USA", // this.customerAddress + " " + this.customerDetailAddress,
        //   dropOffPhoneNumber: "2022072111", // this.customerPhone.split("-").join(""),
        // };
        const editNum = this.store.state.editNum;
        // console.log("editNum==>" + editNum);
        const xiaocunDeliveryId =
          "xiaocun-" +
          this.addDeliveryInfo.externalId +
          (editNum > 1 ? "-" + editNum : "");
        const customerApt = this.addDeliveryInfo.customerDetailAddress
          ? this.customerAptValue + " " + this.addDeliveryInfo.customerDetailAddress
          : "";
        const merchatApt = this.addDeliveryInfo.merchatDetailAddress
          ? this.aptValue + " " + this.addDeliveryInfo.merchatDetailAddress
          : "";
        console.log("customerApt==>" + customerApt);
        console.log("merchatApt==>" + merchatApt);
        const params = {
          deliveryId: xiaocunDeliveryId,
          externalOrderId: this.addDeliveryInfo.externalId,
          externalDeliveryId: this.addDeliveryInfo.pickNumber,
          orderSubtotal: Number(this.addDeliveryInfo.orderSubtotal) * 100,
          tip: Number(this.addDeliveryInfo.tip) * 100,
          pickupTime: deliveryTime,
          pickupAddress: this.getAptAddress(this.merchantAddress, merchatApt),
          pickupPhoneNumber: this.addDeliveryInfo.merchantPhone.split("-").join(""),
          pickupBusinessName: this.addDeliveryInfo.merchantName,
          dropOffAddress: this.getAptAddress(this.customerAddress, customerApt),
          dropOffPhoneNumber: this.addDeliveryInfo.customerPhone.split("-").join(""),
          pickupInstruction: this.addDeliveryInfo.orderNotes,
          dropOffInstruction: this.addDeliveryInfo.dropOffInstruction,
          dropOffContact: this.addDeliveryInfo.firstName
            ? {
                firstName: this.addDeliveryInfo.firstName,
                lastName: this.addDeliveryInfo.lastName,
              }
            : null,
          contactlessDropOff: this.addDeliveryInfo.contactlessDropOff,
        };
        showLoadingToast({
          duration: 0,
          forbidClick: true,
          message: "Loading...",
        });
        const res = await submitIno(params);
        const doorDashResPickUpAddress = res.pickUpAddress;
        const doorDashResDropOffAddress = res.dropOffAddress;
        const deliveryPrice = res.fee;
        const estimatedPickupTime = res.estimatedPickupTime;
        // this.store.commit("updateBusinessAddress", {});
        // this.store.commit("updateUserAddress", {});
        // // this.store.commit("needRefresh", true);
        closeToast();
        // this.store.commit("clearInfo");
        // this.$router.back();

        this.$router.push({
          path: "/infoConfirm",
          query: {
            deliveryId: xiaocunDeliveryId,
            pickUpAddress: doorDashResPickUpAddress,
            dropOffAddress: doorDashResDropOffAddress,
            deliveryPrice: deliveryPrice,
            deliveryTime:
              this.addDeliveryInfo.timeChecked === "asap" ? "ASAP" : estimatedPickupTime,
          },
        });
      } catch (e: any) {
        closeToast();
        const errorMsg = e.innerErrData.errMsg;
        this.showBottomToast(errorMsg);
        console.log(e);
      }
    },
    getAptAddress(address: string, apt: string) {
      if (!apt) {
        return address;
      }
      let addressAry: string[] = address.split(",");
      addressAry[0] = addressAry[0] + ", " + apt;
      return addressAry.join(",");
    },
    showTimeDialog() {
      this.showBottom = true;
    },
    timeChange() {
      console.log("timeChange-->>");
      console.log(this.addDeliveryInfo.timeChecked);
    },
    confirmTime() {
      this.showBottom = false;
      if (this.addDeliveryInfo.timeChecked == "asap") {
        this.addDeliveryInfo.deliveryTime = "ASAP";
      } else {
        const time = this.addDeliveryInfo.value1 === 0 ? "AM" : "PM";
        this.addDeliveryInfo.deliveryTime =
          this.addDeliveryInfo.deliveryHour +
          ":" +
          this.addDeliveryInfo.deliveryMinute +
          " " +
          time;
      }
    },
    goBack() {
      this.$router.back();
    },
    selectBusinessAddress() {
      this.$router.push({
        path: "/location",
        query: {
          addressType: "0",
        },
      });
    },
    selectCustomerAddress() {
      this.$router.push({
        path: "/location",
        query: {
          addressType: "1",
        },
      });
    },
    limitMinute(e: any) {
      let value = parseInt(e.target.value);
      if (value > 59) {
        value = 59;
        this.addDeliveryInfo.deliveryMinute = value + "";
      }
    },
    limitHour(e: any) {
      console.log(e.target.value);
      let value = e.target.value;
      if (parseInt(value) > 12) {
        this.addDeliveryInfo.deliveryHour = "12";
      }
    },
    limitMerchantPhone(e: any) {
      this.addDeliveryInfo.merchantPhone = this.formatPhoneNumber(
        e,
        this.merchantPhoneRef,
        "merchant"
      );
    },
    async limitCustomerPhone(e: any) {
      await nextTick();
      this.addDeliveryInfo.customerPhone = this.formatPhoneNumber(
        e,
        this.customerPhoneRef,
        "customer"
      );
    },
    formatPhoneNumber(number: string, ref: any, type: string) {
      if (number) {
        if (number.length > 3 && number[3] !== "-") {
          number = number.substring(0, 3) + "-" + number.substring(3);
          this.setCursorToEnd(ref, type);
        }
        if (number.length > 7 && number[7] !== "-") {
          number = number.substring(0, 7) + "-" + number.substring(7);
          this.setCursorToEnd(ref, type);
        }
      }
      return number;
    },
    async setCursorToEnd(ref: any, type: string) {
      await nextTick();
      const input = ref.$el.querySelector("input");
      input.focus();
      let len = 0;
      if (input) {
        if (type === "merchant") {
          len = this.addDeliveryInfo.merchantPhone.length;
        } else {
          len = this.addDeliveryInfo.customerPhone.length;
        }
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(len, len);
        } else if (input.createTextRange) {
          const range = input.createTextRange();
          range.collapse(true);
          range.moveEnd("character", len);
          range.moveStart("character", len);
          range.select();
        }
      }
    },
    getTimeStamp() {
      let timestamp = 0;

      // 将小时转换为24小时制
      let hours24 = parseInt(this.addDeliveryInfo.deliveryHour);
      if (hours24 === 12) {
        if (this.addDeliveryInfo.value1 === 0) {
          hours24 = 0;
        } else {
          hours24 = 12;
        }
      } else {
        if (this.addDeliveryInfo.value1 === 1) {
          hours24 += 12;
        }
      }

      // 创建日期对象并获取时间戳
      const currentTime = new Date();
      currentTime.setHours(hours24, parseInt(this.addDeliveryInfo.deliveryMinute), 0, 0);
      timestamp = currentTime.getTime();

      return timestamp;
    },
    showBottomToast(msg: string) {
      showToast({
        message: msg,
        position: "bottom",
      });
    },
  },
});
