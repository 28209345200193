import axios from '@/uitls/axios/request';
import RequestError from '@/core/RequestError';

export default async function deleteDeliveries (id: string): Promise<null> {
  try {
    const uri = `/businesses/services/deliveries/${id}`;
    const response = await axios.delete(uri);
    const data = response.data;
    if (response.status === 200) {
      return null;
    } else {
      throw new RequestError(response.status, response.statusText, data);
    }
  } catch (e: any) {
    console.log(e);
    throw new RequestError(e.response.status, e.response.statusText, e.response.data);
  }
}
