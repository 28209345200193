import axios from '@/uitls/axios/request';
const uri = '/businesses/services/deliveries/list/active';
interface RequestBody {
  pageCapacity: number;
  pageNumber: number
}
export default async function getActiveList (requestBody: RequestBody): Promise<any> {
  const response = await axios.get(uri, { params: requestBody });
  const data = response.data;
  return data;
}
