
import {
  defineComponent,
  onMounted,
  ref,
  nextTick,
  computed,
  onDeactivated,
} from "vue";
import { useStore } from "vuex";
import acceptance from "@/service/postors/acceptance";
import { showLoadingToast, closeToast, showToast } from "vant";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "InfoConfirmPage",
  // components: {
  //   Location,
  // },
  setup() {
    // 组件挂载后执行的操作
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    // let deliveryPrice = ref(false);
    let addDeliveryInfo = computed(() => store.state.addDeliveryInfo);
    // let merchantAddress = ref("");
    // let customerAddress = ref<string | undefined>("");
    const merchantAddress = computed(() => store.state.businessAddress.address);
    const customerAddress = computed(() => store.state.customerAddress.address);
    const merchantPhoneRef = ref<any>(null);
    const customerPhoneRef = ref<any>(null);

    let pickUpAddress = ref<any>("");
    let dropOffAddress = ref<any>("");
    let deliveryPrice = ref<any>(0);
    let deliveryTime = ref<any>(null);
    let deliveryId = ref<any>("");

    onMounted(() => {
      console.log("onMounted---->");
      console.log(store.state.businessAddress);
      pickUpAddress.value = route.query.pickUpAddress;
      dropOffAddress.value = route.query.dropOffAddress;
      deliveryPrice.value = route.query.deliveryPrice;
      deliveryTime.value = route.query.deliveryTime;
      deliveryId.value = route.query.deliveryId;
      console.log(route.query.deliveryId);
      console.log(deliveryId.value);

      if (deliveryTime.value !== "ASAP") {
        deliveryTime.value = formatTimestampToTime(deliveryTime.value);
      }
    });
    const getPhoneStr = (phone: string) => {
      const phoneStr = phone.split("-").join(" ");
      return phoneStr;
    };
    const formatTimestampToTime = (timestamp: string) => {
      // 将时间戳转换为 Date 对象
      const date = new Date(Number(timestamp));

      // 获取小时和分钟
      let hours = date.getHours();
      const minutes = ("0" + date.getMinutes()).slice(-2); // 保证分钟始终为两位数

      // 设置 AM 或 PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // 将 24 小时制转换为 12 小时制
      hours = hours % 12;
      hours = hours ? hours : 12; // 将0小时转换为12小时

      return `${hours}:${minutes} ${ampm}`;
    };
    const reEdit = () => {
      store.commit("reEdit");
      router.back();
    };
    const submit = async () => {
      try {
        showLoadingToast({
          duration: 0,
          forbidClick: true,
          message: "Loading...",
        });
        await acceptance(deliveryId.value);
        store.commit("updateBusinessAddress", {});
        store.commit("updateUserAddress", {});
        store.commit("clearInfo");
        closeToast();
        // this.$router.back();
        router.push({
          path: "/",
        });
      } catch (e: any) {
        closeToast();
        showToast({
          message: e,
          position: "bottom",
        });
      }
    };
    onDeactivated(() => {
      console.log("页面已经被关闭");
    });
    return {
      merchantAddress,
      customerAddress,
      merchantPhoneRef,
      customerPhoneRef,
      store,
      addDeliveryInfo,
      pickUpAddress,
      dropOffAddress,
      deliveryPrice,
      deliveryTime,
      formatTimestampToTime,
      reEdit,
      submit,
      deliveryId,
      getPhoneStr,
    };
  },
});
