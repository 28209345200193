<template>
  <div id="app">
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view> -->
    <router-view>
    </router-view>
  </div>
</template>
<style lang="stylus">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  color: #333;
  max-width: 10rem;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.van-toast {
  z-index: 1000200 !important;
}
</style>
