interface LocationQueryResponse {
  'address': string;
  'latitude': number;
  'longitude': number;
}
type response = LocationQueryResponse;
function processLocationQuery (response: response): LocationQueryResponse {
  response = {
    latitude: response.latitude,
    longitude: response.longitude,
    address: response.address
  };
  return response;
}
export default processLocationQuery;
