
import { defineComponent, onMounted, nextTick } from "vue";
// import { useMutations } from 'vuex';
import { useStore } from "vuex";
import { showToast } from "vant";
import tools from "@/uitls/tools";
import CryptoJS from "crypto-js";
import userLogin from "@/service/postors/userLogin";
import User from "@/models/User";
import { showSuccessToast, showFailToast } from "vant";
import { ref } from "vue";

export default defineComponent({
  name: "LoginPage",
  setup() {
    // 组件挂载后执行的操作
    const store = useStore();
    const fieldRef = ref<any>(null);
    onMounted(() => {
      console.log("LoginPage==>" + store.state.count);
    });
    return {
      store,
      fieldRef,
    };
  },
  data() {
    return {
      count: 0,
      testPhone: "",
      phoneNumber: "",
      passwordValue: "",
      greeting: "Hello, Vue 3!",
    };
  },
  methods: {
    mounted() {
      console.log("mounted---->>>");
    },
    incrementCount() {
      this.count++;
    },
    login() {
      // const newUser = {
      //   fullPhoneNumber: "19164778934",
      //   phoneNumber: "9164778934",
      //   internationalCode: "1",
      //   userType: 129,
      // };

      // this.store.commit("updateUser", newUser);
      // // this.store.commit("increment");
      // this.$router.replace({
      //   path: "/addDelivery",
      //   query: {
      //     phone: "5555555555",
      //   },
      // });thi
      console.log(this.encodePassword("12345678"));
    },
    // 登录
    // 修改手机号
    limitPhone(e: any) {
      this.phoneNumber = this.formatPhoneNumber(e);
    },
    formatPhoneNumber(number: string) {
      if (number) {
        if (number.length > 3 && number[3] !== "-") {
          number = number.substring(0, 3) + "-" + number.substring(3);
          this.setCursorToEnd();
        }
        if (number.length > 7 && number[7] !== "-") {
          number = number.substring(0, 7) + "-" + number.substring(7);
          this.setCursorToEnd();
        }
      }
      return number;
    },
    encodePassword(password: string) {
      const md5Encode = CryptoJS.MD5(password);
      let encodeStr = md5Encode.toString(CryptoJS.enc.Hex);
      const hashEncode = CryptoJS.SHA256(encodeStr);
      encodeStr = hashEncode.toString(CryptoJS.enc.Hex);
      const hashEncode2 = CryptoJS.SHA256(encodeStr);
      encodeStr = hashEncode2.toString(CryptoJS.enc.Hex);
      return encodeStr;
    },
    async setCursorToEnd() {
      await nextTick();
      const input = this.fieldRef.$el.querySelector("input");
      input.focus();
      if (input) {
        const len = this.phoneNumber.length;
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(len, len);
        } else if (input.createTextRange) {
          const range = input.createTextRange();
          range.collapse(true);
          range.moveEnd("character", len);
          range.moveStart("character", len);
          range.select();
        }
      }
    },
    async enterLogin() {
      const phoneNumber = this.phoneNumber.split("-").join("");
      const keepCredentials = true;
      if (phoneNumber.length !== 10) {
        const Message = "Incorrect phone number format"; //this.$t("login.phoneNumberIncorrsct") as string;
        showFailToast(Message);
      } else {
        const fullPhoneNumber = "1" + phoneNumber;
        try {
          let verificationCode = "";
          const passwordValue = this.encodePassword(this.passwordValue);
          verificationCode = JSON.stringify({
            passwordType: "general",
            password: passwordValue,
          });
          await userLogin({
            fullPhoneNumber,
            verificationCode,
            keepCredentials,
          });
          // 缓存登录信息
          const userEntity = await User.getUser();
          this.store.commit("updateUser", userEntity);
          showSuccessToast("Login successful");
          this.$router.push({
            path: "/",
          });
        } catch (e) {
          showFailToast("Login Failed");
          console.log(e);
        }
      }
    },
  },
});
