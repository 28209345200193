import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import store from '@/store';
import router from '@/router';
import axiosConfig from '@/configs/http-config';
// import MockAdapter from 'axios-mock-adapter';
const service = axios.create({
  timeout: 30000,
  baseURL: axiosConfig.baseURL,
  withCredentials: true
});

// const mockAdapter = new MockAdapter(service);
// mockAdapter.onAny().reply(mockAdapterConfig).restore();

// const languagesMapper = {
//   en: 'en-US',
//   'zh-CN': 'zh-CN'
// };
service.interceptors.request.use((config: AxiosRequestConfig) => {
  // 获取全部语言并删除语言参数
  if (config.params && config.params.allLocales) {
    config.headers['Accept-Language'] = 'all-Locales';
    delete config.params.allLocales;
  } else {
    config.headers['Accept-Language'] = 'en-US' // localStorage.getItem('lang');
  }
  return config;
}, (error: any) => {
  console.log(error);
  Promise.reject(error);
});

service.interceptors.response.use(
  (response: AxiosResponse) => {
    const data = response.data;
    if (response.status >= 300) {
      if (response.status === 401) {
        store.commit('clearLogin');
        router.replace({ path: '/login' });
        return Promise.reject(new Error('authentication failed'));
      }
      return Promise.reject(new Error(`${response.statusText}|${response.status}`));
    } else {
      return response;
    }
  },
  (error: AxiosError) => {
    if (error && error.message && error.message.includes('code 401')) {
          store.commit('clearLogin');
          router.replace({ path: '/login' });
        }
        return Promise.reject(error);
  },
  // (error: Error) => {
  //   if (error && error.message && error.message.includes('code 401')) {
  //     store.commit('clearLogin');
  //     router.replace({ path: '/login' });
  //   }
  //   return Promise.reject(error);
  // }
);

export default service;
